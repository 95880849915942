<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Impostazioni Stampante"
    :route-father="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:content>
      <!--<v-row class="pa-0">
        <v-col class="pa-0" cols="4">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-camera</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>Fotocamera</v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-select
            v-model="cameraDevice"
            filled
            dense
            :items="cameraOptions"
            :item-text="(el) => {return el.label || el.groupId}"
            return-object
            :loading="loadingCamera"
            :menu-props="{offsetY: true}"
            style="max-width: 300px"
            hide-details="auto"
            @change="handleSelectCameraDevice"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-checkbox
            :value="disableAskDevices"
            :label="$translations.t('settings.disableAskDevices.title')"
            v-debounce:200.change="setDisableAskDevices"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-subheader class="mt-5">Stampante RT</v-subheader>
      <v-row class="pa-0">
        <v-col class="pa-0" cols="4">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-printer-wireless</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>Indirizzo</v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col class="pa-0 d-flex align-center">
          <v-form v-model="ipValid">
            <v-text-field
              style="max-width: 300px"
              v-model="rtPrinterAddress"
              filled
              dense
              hide-details="auto"
              label="Indirizzo stampante RT"
            ></v-text-field>
          </v-form>
          <v-btn icon class="mx-2" @click="performAutoSearch">
            <v-icon>mdi-feature-search-outline</v-icon>
          </v-btn>
          <v-scroll-y-transition>
            <v-btn 
              icon 
              class="mx-2" 
              @click="setRtPrinterAddress(rtPrinterAddress)"
              v-if="rtPrinterAddress != rtPrinterAddressSetting && ipValid"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-scroll-y-transition>
        </v-col>
      </v-row>-->
      <v-col class="px-10 text-subtitle-1">
        <span class="text-h6 black--text">Header Scontrino</span>
        <v-row class="pa-0 pt-5">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Nome azienda</v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-text-field
              v-model="receipt[0]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Indirizzo azienda</v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-text-field
              v-model="receipt[1]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Partita IVA</v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-text-field
              v-model="receipt[2]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Dettaglio 1</v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-text-field
              v-model="receipt[3]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Dettaglio 2</v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-text-field
              v-model="receipt[4]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Dettaglio 3</v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-text-field
              v-model="receipt[5]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col class="pa-0" cols="4">
            <v-list-item>
              <v-list-item-content>Dettaglio 4</v-list-item-content>
            </v-list-item>
          </v-col>
            <v-col>
            <v-text-field
              v-model="receipt[6]"
              counter
              maxlength="40"
              filled
              dense
              style="max-width: 300px"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 pt-5 pb-2">
          <div></div>
          <v-spacer></v-spacer>
          <v-btn 
          color="primary"
          :loading="loading"
          :disabled="formValid"
          @click="changeHeader">
            Cambia header scontrino
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="mt-10">
          <span class="text-h6 black--text">Logo Scontrino</span>
        </v-row>
        <v-row class="pa-0 pt-5">
          <v-col class="black--text">
            Il logo deve essere in formato .bmp con dimensione non superiore a 20kb (si consiglia di salvare il file come bmp monocromatico). <br>
            Deve contenere 600px di larghezza per essere centrato.
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col>
            <v-btn @click="uploadLogo" color="primary">
              Carica Logo
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pa-0 pb-2">
          <v-col class="black--text">
            Una volta caricato il logo, premi il pulsante "Cambia logo" per applicarlo.
          </v-col>
        </v-row>
        <v-row class="pa-0 pb-2">
          <v-col class="black--text">
            Una volta cambiato il logo, stampare un nuovo scontrino per far aggiornare la stampante, dagli scontrini successivi stamperà il nuovo logo.
          </v-col>
        </v-row>
        <v-row class="pa-0 pt-5 pb-2">
          <div></div>
          <v-spacer></v-spacer>
          <v-btn 
          color="primary"
          :loading="logoLoading"
          @click="changeLogo">
            Cambia logo scontrino
          </v-btn>
        </v-row>
      </v-col>

      <v-dialog
        persistent
        width="300"
        v-model="searchDialog"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-title>{{dialogTitle}}</v-card-title>
          <v-card-text>
            <v-progress-linear
              v-model="progress"
              color="white"
              class="mb-0"
            ></v-progress-linear>
            <div
              style="transition: all 0.5s;"
              :class="{
                'my-2': !!addressFound,
                'text-h6': !!addressFound
              }"
              :style="{
                'text-align': !!addressFound ? 'center' : 'left'
              }"
            >{{checkingAddress}}</div>
            <v-expand-transition>
              <div 
                class="d-flex align-center justify-center" 
                v-if="!!addressFound"
              >
                <v-btn icon @click="setAndClose">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn icon @click="searchDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn icon @click="searchPrinter">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <v-btn 
                class="mt-2"
                v-if="addressNotFound" 
                text 
                @click="searchDialog = false" 
              >chiudi</v-btn>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import settingsService from '@/services/settings/settings.service.js'
import IpInput from '@/components/common/IpInput.vue'
import cameraDeviceService from '@/services/devices/camera.device'
import barcodeScannerDeviceService from '@/services/devices/barcodeScanner.device'
import debounce from "@/mixins/common/debounce.js"
import searchPrinter from './searchPrinter'
import Printer from '@/services/devices/rtPrinter' 
import cashDeskService from "@/services/cashDesks/cashDesks.service.js"
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'


export default {
  name: "DevicesSettings",
  mixins: [debounce, searchPrinter],
  components: {
    IpInput,
    FullScreenDialog
  },
  props: {
    pathToGoBack: {
      type: String,
      default: "/settings"
    },
    pathName: {
      type: String,
    },
    filters: {
      type: Array
    }
  },
  data: function() {
    return {
      dialog: true,
      disableAskDevices: false,

      cameraOptions: [],
      loadingCamera: false,
      cameraDevice: {},

      barcodeScannerOptions: [],
      loadingBarcodeScanner: false,
      ipValid: false,
      barcodeScannerDevice: {},
      rtPrinterAddress: '',
      rtPrinterAddressSetting: undefined,

      receipt:['','','','','','',''],
      loading: false,
      logoLoading: false,
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      }
    }
  },
  mounted: function() {
    this.loadDeviceSettings()

    this.cameraDevice = cameraDeviceService.device(true)
    cameraDeviceService.on('connect', ({device}) => {
      if(this.cameraOptions.length == 0 && !!device) {
        this.cameraOptions.push(device)
      }
      this.cameraDevice = device
    })
    cameraDeviceService.getDeviceOptions().then((options) => {
      this.cameraOptions = options
    }).catch(() => {

    })

    const device = barcodeScannerDeviceService.device(true)
    if(this.barcodeScannerOptions.length == 0 && !!device) {
      this.barcodeScannerOptions.push(device)
    }
    this.barcodeScannerDevice = device

    barcodeScannerDeviceService.on('connect', ({device: deviceFromConnect}) => {
      if(this.barcodeScannerOptions.length == 0) {
        this.barcodeScannerOptions.push(deviceFromConnect)
      }
      this.barcodeScannerDevice = deviceFromConnect
    })
  },
  methods: {
    loadDeviceSettings() {
      settingsService.list('devices').then((results) => {
        this.disableAskDevices = results.disableAskDevices.value
      })
    },
    setDisableAskDevices(newVal) {
      this.$settings.set('disableAskDevices', newVal).then(() => { })
    },

    handleBarcodeSelectClick() {
      barcodeScannerDeviceService.getDeviceOptions().then((options) => {
        this.barcodeScannerOptions = options
      })
    },
    handleSelectCameraDevice(device) {
      this.loadingCamera = true
      cameraDeviceService.selectDevice(device).then(() => { 
        this.loadingCamera = false
      })
    },
    handleSelectBarcodeScannerDevice(device) {
      this.loadingBarcodeScanner = true
      barcodeScannerDeviceService.selectDevice(device).then(() => { 
        this.loadingBarcodeScanner = false
      })
    },
    setAndClose() {
      this.setRtPrinterAddress(this.addressFound)
      this.closeSearchDialog()
    },
    setRtPrinterAddress(address) {
      this.$settings.set('rtPrinterAddress', address)
    },
    performAutoSearch() {
      const confirmed = confirm('Verrà eseguita la ricerca automatica della stampante, che impiegherà diversi minuti, vuoi cominciarla?')

      if(confirmed) {
        this.searchPrinter()
      }
    },
    async changeHeader(){
      let val = ''
      let space = 0
      this.loading = true

      for(let i = 0; i < this.receipt.length; i++){
        space = (40 - this.receipt[i].length) / 2
        val = ' '.repeat(space) + this.receipt[i] + ' '.repeat(space)
        if(val.length < 40)
          val = val + ' '
        await Printer.setHeader('0' + (i+1) + val)
      }

      await Printer.testHeader()
      
      Printer.saveHeader().then(res=>{
        if(!!res.attributes && res.attributes.status == '17')
          this.$delegates.snackbar("Attenzione! Bisogna fare una chiusura fiscale per poter cambiare l'header dello scontrino", 10000)
      })
      this.loading = false
    },
    async changeLogo(){
      this.logoLoading = true

      await Printer.setLogo()
      await Printer.saveLogo()

      this.logoLoading = false
    },
    async getAddress(){
      let promise = new Promise((resolve, reject) => {
        cashDeskService.getOpened().then((openedCashDesk) => {
          this._address = openedCashDesk.printer;
          resolve(openedCashDesk.printer);
        })
      })
      let url = await promise;
      return url;
    },
    async uploadLogo(){
      let address = await this.getAddress();
      let a = document.createElement('a');
      a.href = `https://${address}/cgi-bin/upload.cgi`;
      a.target = '_blank';
      a.click();
    }
  },
  computed:{
    formValid(){
      return !this.receipt[0].length || !this.receipt[1].length || !this.receipt[2].length
    }
  },
  settings: {
    disableAskDevices: {
      change(newVal) {
        this.disableAskDevices = newVal
      }
    },
    rtPrinterAddress: {
      initial(newValue) {
        this.rtPrinterAddress = newValue
        this.rtPrinterAddressSetting = newValue
      },
      change(newValue) {
        this.rtPrinterAddress = newValue
        this.rtPrinterAddressSetting = newValue
      }
    }
  }
}
</script>

<style>

</style>